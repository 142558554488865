import * as t from "io-ts";
import { LngLat } from "@yandex/ymaps3-types";

export const tLngLat = t.tuple([
  t.number,
  t.number,
  t.union([t.number, t.undefined]),
]);

export const tMarker = t.type({
  id: t.union([t.number, t.undefined]),
  coords: tLngLat,
  address: t.type({
    isAuto: t.boolean,
    name: t.string,
  }),
  description: t.string,
  especialFields: t.array(t.string),
  pics: t.array(t.unknown),
  isPopupOpen: t.union([t.boolean, t.undefined]),
  positionPopup: t.union([t.literal("left"), t.literal("right")]),
});

type Marker = Omit<t.TypeOf<typeof tMarker>, "coords"> & {
  coords: LngLat;
};

export default Marker;
// export default interface Marker extends t.TypeOf<typeof tMarker> {
//   coords: LngLat;
// }

// type Marker = t.TypeOf<typeof tMarker> extends <{coords: LngLat}> ;

// export default interface Marker {
//   id?: number;
//   coords: LngLat;
//   address: {
//     isAuto: boolean,
//     name: string
//   };
//   description: string;
//   especialFields: string[];
//   pics: unknown[];
//   isPopupOpen?: boolean;
//   positionPopup: "left" | "right";
// }
