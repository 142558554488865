interface RenderedFieldProps {
  field: string;
  iconSize?: number;
}

const iconDict: Record<string, string> = {
  rub: "https://img.icons8.com/deco/24/ruble.png",
  base: "https://img.icons8.com/deco/24/connection-status-on.png",
  usd: "https://img.icons8.com/deco/24/us-dollar.png",
  time: "https://img.icons8.com/deco/24/clock.png"
};

const RenderedField = ({ field, iconSize }: RenderedFieldProps) => {
  const regex = /^\{(.*)\}/;
  const match = field.match(regex);

  const expression = match && match[1] ? match[1] : "base";

  return (
    <img
      width={iconSize || 24}
      height={iconSize || 24}
      src={iconDict[expression]}
    />
  );
};

export default RenderedField;
