import { useCallback, useEffect, useState } from "react";
import React from "react";

import {
  Feature,
  LngLat,
  VectorCustomizationElements,
  YMapLocationRequest,
} from "@yandex/ymaps3-types";

import {
  YMap,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapMarker,
  YMapListener,
  YMapComponentsProvider,
  YMapClusterer,
  YMapCustomClusterer,
  YMapDefaultMarker,
} from "ymap3-components";

import {
  getCustomizationJSON,
  ymapCustomizationLabels,
} from "./customizationStyles";

import env from "../../../../env.json";

import "./index.scss";
import KingTabakMarkers from "./components/KingTabakMarkers";
import Marker from "../../types";
import { YMapDefaultModules } from "ymap3-components/dist/src/types";

interface KingTabakMapProps {
  theme: ymapCustomizationLabels;
  markers: Marker[];
  currentMarker: Marker | null;
  address: { isAuto: boolean; name: string };
  setAddress: React.Dispatch<
    React.SetStateAction<{ isAuto: boolean; name: string }>
  >;
  setNewCoords: React.Dispatch<React.SetStateAction<LngLat | null>>;
  setCurrentMarker: React.Dispatch<React.SetStateAction<Marker | null>>;
  onLoad: ((params: YMapDefaultModules) => any) | undefined;
}

export default function KingTabakMap({
  theme,
  markers,
  currentMarker,
  address,
  setAddress,
  setNewCoords,
  setCurrentMarker,
  onLoad,
}: KingTabakMapProps) {
  const handleCoords = (coords: LngLat) => {
    const crds: LngLat = [
      Math.round(coords[0] * 100000) / 100000,
      Math.round(coords[1] * 100000) / 100000,
    ];

    setNewCoords(crds);
  };

  useEffect(() => {
    console.log(currentMarker);
  }, [currentMarker]);

  return (
    <main className="KingTabakMapWrapper">
      <YMapComponentsProvider apiKey={env.YMAP_API_KEY} onLoad={onLoad}>
        <YMap
          location={env.DEFAULT_LOCATION as YMapLocationRequest}
          mode="vector"
        >
          <YMapDefaultSchemeLayer
            // @ts-ignore
            customization={
              getCustomizationJSON(theme) as VectorCustomizationElements[]
            }
          />

          <YMapDefaultFeaturesLayer />

          {markers.map((marker) => (
            <KingTabakMarkers
              coords={marker.coords}
              marker={marker}
              isCurrent={currentMarker?.id === marker.id}
            ></KingTabakMarkers>
          ))}

          <YMapListener
            onClick={(obj, event) => {
              if (obj) {
                switch (obj.type) {
                  case "marker":
                    setNewCoords(obj.entity.coordinates);
                    // TO-DO: add typeschecking
                    if (obj.entity?.properties?.marker) {
                      if (
                        // @ts-ignore
                        currentMarker?.id === obj.entity.properties.marker.id
                      ) {
                        setCurrentMarker(null);
                      } else {
                        setCurrentMarker(
                          (obj.entity.properties.marker ||
                            null) as Marker | null
                        );
                      }
                    }
                    break;
                  case "hotspot":
                    if (
                      !currentMarker &&
                      obj.entity.properties.name &&
                      address.isAuto
                    ) {
                      setAddress({
                        isAuto: true,
                        // TO-DO: add typeschecking
                        // @ts-ignore
                        name: obj.entity.properties.name,
                      });
                      handleCoords(event.coordinates);
                    }
                    break;
                  default:
                    // addre;
                    handleCoords(event.coordinates);
                    break;
                }
              } else {
                handleCoords(event.coordinates);
              }
            }}
          ></YMapListener>
        </YMap>
      </YMapComponentsProvider>
    </main>
  );
}
