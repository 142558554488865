import Marker from "../../../types";
import RenderedField from "../../MenuEditor/components/RenderedField";

export default function MapMarkerPopup(marker: Marker) {
  return (
    <div className="MapMarkerPopupWrapper modal">
      <article className="MapMarkerPopup">
        <section className="MapMarkerPopup-adress">
          <h2>{marker.address.name}</h2>
        </section>
        {marker.especialFields.length > 0 && (
          <section className="MapMarkerPopup-especial">
            <ul>
              {marker.especialFields.map((val) => (
                <li>
                  <RenderedField field={val} />
                  <span>{val.replace(/^\{(.*)\}/, "")}</span>
                </li>
              ))}
            </ul>
          </section>
        )}

        {marker.description.length > 0 && (
          <section className="MapMarkerPopup-description">
            {marker.description}
          </section>
        )}

        {marker.pics.length > 0 && (
          <section className="MapMarkerPopup-pics">
            {marker.pics.map((pic) => (
              <img alt="not found" src={pic as string} />
            ))}
          </section>
        )}
      </article>
    </div>
  );
}
