import { useCallback, useEffect, useState } from "react";
import React from "react";

import { LngLat } from "@yandex/ymaps3-types";

import { YMapMarker } from "ymap3-components";

import "../index.scss";
import MarkerPoint from "./MapMarkerPoint";
import Marker from "../../../types";
import MapMarkerPopup from "./MapMarkerPopup";

interface KingTabakMarkersProps {
  marker: Marker;
  coords: LngLat;
  isCurrent: boolean; // Признак текущей метки
}

// TO-DO: some hell code
const SomeHell = ({
  isCurrent,
  marker,
}: {
  isCurrent: boolean;
  marker: Marker; // Текущая метка, которую нужно отобразить в попапе
}) => {
  console.log(isCurrent);
  return isCurrent ? <MapMarkerPopup {...marker} /> : <></>;
};

export default function KingTabakMarkers({
  marker,
  coords,
  isCurrent,
}: KingTabakMarkersProps) {
  return (
    <YMapMarker
      coordinates={coords}
      properties={{ marker }}
      zIndex={isCurrent ? 1000 : 0}
    >
      <div className="MapMarkerWrapper">
        <div className={`MapMarker ${!isCurrent ? "MapMarker-hover" : ""}`}>
          <MarkerPoint></MarkerPoint>
        </div>
        <SomeHell isCurrent={isCurrent} marker={marker} />
      </div>
    </YMapMarker>
  );
}
