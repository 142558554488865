import ReactDOM from "react-dom/client";
import App from "./App";
import BrowserBlocker from "./App/utils/BrowserBlocker";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <BrowserBlocker>
  <App />
  // </BrowserBlocker>
);
