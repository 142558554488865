import "./styles/index.scss";

import { type LngLat } from "ymaps3";

import { useEffect, useMemo, useState } from "react";
import MenuSettings from "./components/MenuSettings";
import MenuEditor from "./components/MenuEditor";
import Marker, { tMarker } from "./types";
import KingTabakMap from "./components/Map";
import { isLeft } from "fp-ts/lib/Either";

export default function App() {
  const [isModalHide, setIsModalHide] = useState<boolean>(
    localStorage.getItem("isModalHide")
      ? localStorage.getItem("isModalHide") === "true"
      : false
  );

  const [isBranded, setIsBranded] = useState<boolean>(
    localStorage.getItem("isBranded")
      ? localStorage.getItem("isBranded") === "true"
      : false
  );

  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    localStorage.getItem("isDarkMode")
      ? localStorage.getItem("isDarkMode") === "true"
      : true
  );

  const [currentMarker, setCurrentMarker] = useState<Marker | null>(null);

  const [markers, setMarkers] = useState<Marker[]>([]);

  const [newCoords, setNewCoords] = useState<LngLat | null>(null);

  const [address, setAddress] = useState<{ isAuto: boolean; name: string }>({
    isAuto: true,
    name: "",
  });

  const handleAddMarker = (newMarker: Marker) => {
    setMarkers([
      ...markers.filter((value) => value.id != newMarker.id),
      newMarker,
    ]);

    fetch(`/api/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newMarker),
    })
      .then((response) => response.text())
      .then((message) => console.log(message))
      .catch((error) => console.error("Ошибка сохранения данных:", error));
  };

  const handleDeleteMarker = (marker: Marker) => {
    setMarkers(markers.filter((value) => value.id != marker.id));
    fetch(`/api/data/${marker.id}`, {
      method: "DELETE",
    })
      .then((response) => response.text())
      .then((message) => console.log(message))
      .catch((error) => console.error("Ошибка удаления данных:", error));
    setCurrentMarker(null);
  };

  const theme = useMemo(() => {
    localStorage.setItem("isBranded", JSON.stringify(isBranded));
    localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));

    const theme = isBranded
      ? isDarkMode
        ? "BrandedDark"
        : "BrandedLight"
      : isDarkMode
      ? "ClassicDark"
      : "ClassicLight";

    document.documentElement.dataset.theme = theme;

    return theme;
  }, [isBranded, isDarkMode]);

  useEffect(() => {
    localStorage.setItem("isModalHide", JSON.stringify(isModalHide));
  }, [isModalHide]);

  return (
    <main>
      <aside className="MenuWrapper">
        <MenuSettings
          isBranded={isBranded}
          setIsBranded={setIsBranded}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          isModalHidden={isModalHide}
          setIsModalHidden={setIsModalHide}
        />

        <MenuEditor
          currentMarker={currentMarker}
          setCurrentMarker={setCurrentMarker}
          newCoords={newCoords}
          setNewCoords={setNewCoords}
          address={address}
          setAddress={setAddress}
          isModalHidden={isModalHide}
          handleAddMarker={handleAddMarker}
          handleDeleteMarker={handleDeleteMarker}
        />
      </aside>

      <KingTabakMap
        theme={theme}
        setNewCoords={setNewCoords}
        address={address}
        setAddress={setAddress}
        setCurrentMarker={setCurrentMarker}
        currentMarker={currentMarker}
        markers={markers}
        onLoad={() => {
          fetch(`/api/data`)
            .then((response) => {
              console.log("Response:", response);
              return response.json();
            })
            .then((data: unknown) => {
              const ihopeDataIsArray = Array.isArray(data) ? data : null;

              if (!ihopeDataIsArray) {
                console.error("Ожидался массив, но получено:", data);
                return;
              }

              const decodedData = ihopeDataIsArray
                .map((maybeMarker: unknown) => {
                  const decoded = tMarker.decode(maybeMarker);
                  if (isLeft(decoded)) {
                    console.error(
                      `Ошибка декодирования маркера: ${decoded.left}`
                    );
                    return null;
                  }

                  return decoded.right as Marker;
                })
                .filter((marker) => marker != null);

              decodedData.forEach((marker) => {
                if (marker) {
                  const [x, y] = marker.coords;
                  const xDecimals = x.toString().split(".")[1]?.length || 0;
                  const yDecimals = y.toString().split(".")[1]?.length || 0;

                  const minDecimals = Math.min(xDecimals, yDecimals);

                  marker.coords[0] = parseFloat(x.toFixed(minDecimals));
                  marker.coords[1] = parseFloat(y.toFixed(minDecimals));
                }
              });

              setMarkers(decodedData);
            })
            .catch((error) => console.error("Ошибка получения данных:", error));
        }}
      ></KingTabakMap>
    </main>
  );
}
