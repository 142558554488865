import { LngLat } from "@yandex/ymaps3-types";

const handleHandWriteCoords = (text: string | null) => {
  if (text) {
    const parsedCrds = text
      .split(",")
      .map((val: string) => parseFloat(val.trim()));
    if (!isNaN(parsedCrds[0]) && !isNaN(parsedCrds[1])) {
      return [parsedCrds[1], parsedCrds[0]] as LngLat;
    } else {
      return undefined;
    }
  } else {
    return null;
  }
};

export default handleHandWriteCoords;
