import React from "react";
import { useSpring, animated } from "react-spring";
import "./index.scss";

interface MenuSettingsProps {
  isBranded: boolean;
  setIsBranded: React.Dispatch<React.SetStateAction<boolean>>;
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  isModalHidden: boolean;
  setIsModalHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingOption = ({
  isActive,
  setActive,
  iconActive,
  iconInactive,
  labelActive,
  labelInactive,
  animationProps,
  iconId,
}: {
  isActive: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  iconActive: string;
  iconInactive: string;
  labelActive: string;
  labelInactive: string;
  animationProps: any;
  iconId: string;
}) => {
  const iconAnimation = useSpring({
    transform: isActive ? "scale(1)" : "scale(0.9)",
    opacity: isActive ? 1 : 0.8,
    config: { tension: 200, friction: 10 },
  });

  return (
    <button
      className="MenuOption"
      onClick={() => setActive(!isActive)}
    >
      <animated.img
        width="24"
        height="24"
        src={isActive ? iconActive : iconInactive}
        alt={isActive ? labelActive : labelInactive}
        className="MenuOption-icon"
        id={iconId}
        style={iconAnimation}
      />
      <label
        htmlFor={iconId}
        className={`MenuOption-label ${
          isActive ? "MenuOption-icon_active" : ""
        }`}
      >
        {isActive ? labelActive : labelInactive}
      </label>
    </button>
  );
};

export default function MenuSettings({
  isBranded,
  setIsBranded,
  isDarkMode,
  setIsDarkMode,
  isModalHidden,
  setIsModalHidden,
}: MenuSettingsProps) {
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    reset: true,
  });

  const settingsOptions = {
    "MenuOption-hideSettings": {
      isActive: !isModalHidden,
      setActive: () => setIsModalHidden(!isModalHidden),
      iconActive: "https://img.icons8.com/deco/24/invisible.png",
      iconInactive: "https://img.icons8.com/deco/24/visible.png",
      labelActive: "Скрыть",
      labelInactive: "Показать",
      iconId: "SettingsOptionForIsModalHidden",
      className: "MenuOption-hideSettings",
      isHide: false,
    },
    "MenuOption-isBranded": {
      isActive: isBranded,
      setActive: setIsBranded,
      iconActive: "https://img.icons8.com/deco/24/rating-circled.png",
      iconInactive: "https://img.icons8.com/deco/24/connection-status-on.png",
      labelActive: "Фирменный",
      labelInactive: "Спокойный",
      iconId: "SettingsOptionForIsBranded",
      className: "MenuOption-isBranded",
      isHide: isModalHidden,
    },
    "MenuOption-isDarkMode": {
      isActive: isDarkMode,
      setActive: setIsDarkMode,
      iconActive: "https://img.icons8.com/deco/24/moon.png",
      iconInactive: "https://img.icons8.com/deco/24/summer.png",
      labelActive: "Темный",
      labelInactive: "Светлый",
      iconId: "SettingsOptionForIsDarkMode",
      className: "MenuOption-isDarkMode",
      isHide: isModalHidden,
    },
  };

  return (
    <menu
      className={`Menu Menu-settings modal ${
        isModalHidden ? "hidden" : "visible"
      }`}
    >
      {Object.values(settingsOptions).map((option, index) => (
        <li
          className={`MenuOption ${option.className} ${
            option.isHide ? "hidden" : "visible"
          }`}
        >
          <SettingOption {...option} animationProps={animationProps} />
        </li>
      ))}
    </menu>
  );
}
