import React, { useState } from "react";
import RenderedField from "./RenderedField";

interface EspecialFieldsProps {
  especialFields: string[];
  setEspecialFields: React.Dispatch<React.SetStateAction<string[]>>;
}

const EspecialFields: React.FC<EspecialFieldsProps> = ({
  especialFields,
  setEspecialFields,
}) => {
  const [especialFieldInput, setEspecialFieldInput] = useState("");

  const addEspecialField = () => {
    if (especialFieldInput) {
      setEspecialFields([...especialFields, especialFieldInput]);
      setEspecialFieldInput("");
    }
  };

  return (
    <>
      <li className="MenuOption MenuOptions-field">
        <label
          htmlFor="input-especial-fields"
          className="MenuOption-label_icon"
        >
          <img
            className="MenuOption-icon"
            src="https://img.icons8.com/deco/24/content.png"
            alt="Especial Fields Icon"
          />
        </label>
        <input
          id="input-especial-fields"
          type="text"
          placeholder="Специальные поля"
          value={especialFieldInput}
          onChange={(e) => setEspecialFieldInput(e.target.value)}
          className="MenuOption-input"
        />
        <button
          type="button"
          className="MenuOption-button"
          onClick={addEspecialField}
        >
          <img
            width="16"
            height="16"
            src="https://img.icons8.com/deco/16/add.png"
            alt="add"
          />
        </button>
      </li>

      {especialFields.length > 0 && (
        <li className="MenuOption MenuOptions-field">
          <ul className="MenuOptions-field_list">
            {especialFields.map((field, index) => (
              <li>
                <RenderedField field={field} />
                <input
                  type="text"
                  value={field}
                  onChange={(e) =>
                    setEspecialFields(
                      especialFields.map((f, i) =>
                        i === index ? e.target.value : f
                      )
                    )
                  }
                  className="MenuOption-input"
                />
                <button
                  type="button"
                  className="MenuOption-button"
                  onClick={() =>
                    setEspecialFields(
                      especialFields.filter((_, i) => i !== index)
                    )
                  }
                >
                  <img
                    width="16"
                    height="16"
                    src="https://img.icons8.com/deco/16/minus.png"
                    alt="minus"
                  />
                </button>
              </li>
            ))}
          </ul>
        </li>
      )}
    </>
  );
};

export default EspecialFields;
