import { LngLat } from "@yandex/ymaps3-types";
import React, { useState, useEffect } from "react";
import { animated, useSpring } from "react-spring";
import Marker from "../../types";
import InputField from "./components/InputField";
import EspecialFields from "./components/EspecialFields";
import handleHandWriteCoords from "../../utils/handleHandWriteCoords";

interface MenuEditorProps {
  currentMarker: Marker | null;
  setCurrentMarker: React.Dispatch<React.SetStateAction<Marker | null>>;
  newCoords: LngLat | null;
  setNewCoords: React.Dispatch<React.SetStateAction<LngLat | null>>;
  address: { isAuto: boolean; name: string };
  setAddress: React.Dispatch<
    React.SetStateAction<{ isAuto: boolean; name: string }>
  >;

  handleAddMarker: (marker: Marker) => void;
  handleDeleteMarker: (marker: Marker) => void;
  isModalHidden: boolean;
}

const MenuEditor: React.FC<MenuEditorProps> = ({
  currentMarker,
  setCurrentMarker,
  newCoords,
  setNewCoords,
  address,
  setAddress,

  handleAddMarker,
  handleDeleteMarker,

  isModalHidden,
}) => {
  /**
   * State of marker from editor.
   * Saved when data is suddenly replaced by something else (the user clicked on an existing marker)
   */
  const [editorMarker, setEditorMarker] = useState<Marker | null>(null);

  /**
   * Ебучий костыль
   */
  const [oldCoords, setOldCoords] = useState<LngLat | null>(null);
  const [handWriteCoords, setHandWriteCoords] = useState<string | null>(null);

  const [coords, setCoords] = useState<string>("37.55107, 55.74488");
  const [description, setDescription] = useState<string>("");
  const [especialFields, setEspecialFields] = useState<string[]>([]);
  const [pics, setPics] = useState<unknown[]>([]);
  const [positionPopup, setPositionPopup] = useState<"left" | "right">("right");

  const getPic = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  /**
   * Union all states for fields in one object
   */
  const getMarker = (
    isShouldReturnId: boolean = false,
    handCoord: null | LngLat = null
  ) => {
    // @ts-ignore TO-DO
    const markerFromFields: Marker = {
      coords:
        handCoord ||
        (coords.split(",").map((val) => parseFloat(val.trim())) as LngLat),
      address,
      description,
      especialFields,
      pics,
      positionPopup,
    };

    console.log("markerFromFields1", markerFromFields);
    // TO-DO:     Вывести эту проверку в utils, она юзается и в фетче для получения маркеров
    // TO-DO X2:  БЛЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯЯТЬ ЕБУЧИЙ ЯНДЕКС НЕ ПОКАЖЕТ МАРКЕР ЕСЛИ
    //            ТАМ НЕ ТОТ ЖЕ ПОРЯДОК ЧТО У ВСЕХ ОСТАЛЬНЫХ
    const [x, y] = markerFromFields.coords;
    const xDecimals = x.toString().split(".")[1]?.length || 0;
    const yDecimals = y.toString().split(".")[1]?.length || 0;

    const minDecimals = Math.min(xDecimals, yDecimals);

    markerFromFields.coords[0] = parseFloat(x.toFixed(minDecimals));
    markerFromFields.coords[1] = parseFloat(y.toFixed(minDecimals));
    // TO-DO
    console.log("markerFromFields2", markerFromFields);

    if (isShouldReturnId) {
      markerFromFields.id = Math.floor(Math.random() * 9999999);
    }

    return markerFromFields;
  };

  /**
   * Sets all fields at once.
   *
   * If fields is null, sets default values.
   */
  const setFields = (fields: Marker | null) => {
    setCoords(fields ? fields.coords.join(", ") : "37.55107, 55.74488");
    setAddress(fields ? fields.address : { isAuto: true, name: "" });
    setDescription(fields ? fields.description : "");
    setEspecialFields(fields ? fields.especialFields : []);
    setPics(fields ? fields.pics : []);
    setPositionPopup(fields ? fields.positionPopup : "right");
  };

  /**
   * Change editor fields if user clicked on an existing marker.
   */
  useEffect(() => {
    if (!currentMarker) {
      setOldCoords(
        coords.split(",").map((val) => parseFloat(val.trim())) as LngLat
      );
    }

    const mark = getMarker();

    setEditorMarker(mark);

    setFields(currentMarker);
  }, [currentMarker]);

  /**
   * Handle form submission.
   *
   * If current marker exists, updates it with new values.
   * Else creates a new marker.
   */
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const handCoord = handleHandWriteCoords(handWriteCoords);

    if (handCoord === undefined)
      return alert(
        "Введите корректные координаты, или выберите точку на карте"
      );

    if (currentMarker) {
      const changedMarker: Marker = Object.assign({}, currentMarker, {
        coords:
          handCoord ||
          (coords.split(",").map((val) => parseFloat(val.trim())) as LngLat),
        address,
        description,
        especialFields,
        pics,
      });

      handleAddMarker(changedMarker);
      setCurrentMarker(null);
      if (editorMarker) {
        setFields(editorMarker);
        setEditorMarker(null);
      }

      return;
    }

    handleAddMarker(getMarker(true, handCoord));
    setHandWriteCoords(null);
    setNewCoords(null);
  };

  useEffect(() => {
    setHandWriteCoords(null);
  }, [newCoords]);

  /**
   * Cancel editing or creating a new marker.
   *
   * If current marker exists, sets it to null and restores original fields.
   * Else resets form.
   */
  const handleCancel = () => {
    if (currentMarker) {
      setCurrentMarker(null);
      if (editorMarker) {
        setFields(editorMarker);
      }
    } else {
      setFields(null);
    }

    setNewCoords([37.55107, 55.74488]);
  };

  const coordsSpring = useSpring({
    coords: newCoords ? newCoords.join(", ") : coords,
    config: { duration: 400 },
    onChange: ({ value }) => {
      // TODO: Проверить скорость
      // Возможно это полный пиздец

      const parsedCrds = value.coords
        .split(",")
        .map((val: string) => parseFloat(val.trim())) as LngLat;

      const crds = [
        Math.round(parsedCrds[1] * 100000) / 100000,
        Math.round(parsedCrds[1] * 100000) / 100000,
      ];

      setCoords(crds.join(", "));
    },
  });

  useEffect(() => {
    setCoords(newCoords ? newCoords.join(", ") : coords);
  }, [newCoords, coords]);

  return (
    <animated.form
      className={`Menu Menu-editor modal ${
        isModalHidden ? "hidden" : "visible"
      }`}
      onSubmit={handleSubmit}
    >
      <InputField
        id="input-coords"
        type="text"
        placeholder="Координаты (например: 37.588144, 55.733842)"
        value={handWriteCoords !== null ? handWriteCoords : coordsSpring.coords} // Используем локальное состояние
        onChange={(e) => {
          setHandWriteCoords(e.target.value);
        }}
        iconSrc="https://img.icons8.com/deco/24/marker.png"
        iconAlt="Coords Icon"
      />
      <InputField
        id="input-address"
        type="text"
        placeholder="Адрес"
        value={address.name}
        onChange={(e) => setAddress({ isAuto: false, name: e.target.value })}
        iconSrc="https://img.icons8.com/deco/24/map.png"
        iconAlt="Address Icon"
      />
      <InputField
        id="input-description"
        type="text"
        placeholder="Описание"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        iconSrc="https://img.icons8.com/deco/50/align-left.png"
        iconAlt="Description Icon"
        isTextarea={true}
      />
      <EspecialFields
        especialFields={especialFields}
        setEspecialFields={setEspecialFields}
      />
      <li className="MenuOption MenuOptions-field">
        <label
          style={{ width: "100%" }}
          htmlFor="input-pics"
          className="MenuOption-label_icon"
        >
          <img
            className="MenuOption-icon"
            src="https://img.icons8.com/deco/24/image.png"
            alt="Pics Icon"
          />
          <span style={{ textDecoration: "line-through" }}>
            Загрузите фотографии
          </span>
        </label>
        <input
          id="input-pics"
          className="MenuOption-input"
          type="file"
          multiple
          onChange={async (e) => {
            if (e.target.files) {
              const pics = await Promise.all(
                Array.from(e.target.files).map((pic) => getPic(pic))
              );
              setPics(pics);
            }
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={async (e) => {
            e.preventDefault();
            if (e.dataTransfer.files) {
              const pics = await Promise.all(
                Array.from(e.dataTransfer.files).map((pic) => getPic(pic))
              );
              setPics(pics);
            }
          }}
        />
      </li>
      <div className="MenuOption">
        <button type="submit" className="MenuOptions-button">
          {currentMarker === null
            ? "Добавить"
            : currentMarker.coords == newCoords
            ? "Изменить"
            : "Изменить и сдвинуть"}
        </button>
        {currentMarker !== null && (
          <>
            <button
              type="button"
              onClick={() => {
                if (confirm("Удалить маркер?")) {
                  handleDeleteMarker(currentMarker);
                }
              }}
              className="MenuOptions-button"
            >
              Удалить
            </button>
            <button
              type="button"
              onClick={() => {
                // TO-DO: по красоте
                // if (confirm("Сбросить изменения?")) {

                // }

                handleCancel();
              }}
              className="MenuOptions-button"
            >
              Отмена
            </button>
          </>
        )}
      </div>
    </animated.form>
  );
};

export default MenuEditor;
