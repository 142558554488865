export default function MapMarkerPoint() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 495 495"
      xmlSpace="preserve"
      width="32px"
    >
      <g>
        <path
          className="icon-st1"
          d="M485.41,19.33c-1.38,0-2.76,0.63-3.65,2.05C459.16,57.53,413.68,81.8,368.88,81.8 c-48.29,0-89.94-28.22-109.47-69.11c-2.23-4.66-6.73-7.79-11.89-7.79c-5.17,0-9.67,3.14-11.89,7.8 c-19.48,40.89-61.05,69.1-109.34,69.1c-44.81,0-90.4-24.29-113.05-60.46c-0.89-1.42-2.26-2.04-3.65-2.04 c-2.34,0-4.68,1.79-4.68,4.62v132.31c0,5.51,4.46,9.97,9.97,9.97h0c5.51,0,9.97-4.46,9.97-9.97V64.31 c27.73,23.23,64.75,37.44,101.44,37.44c49.73,0,96.01-26.72,121.24-69.05c25.27,42.33,71.6,69.05,121.36,69.05 c36.64,0,73.59-14.18,101.28-37.38v98.4c0,4.85-3.93,8.78-8.78,8.78H41.75c-5.51,0-9.97,4.46-9.97,9.97v0 c0,5.51,4.46,9.97,9.97,9.97h427.11c11.73,0,21.24-9.51,21.24-21.24V23.94C490.1,21.12,487.75,19.33,485.41,19.33z"
        />
        <path
          className="icon-st2"
          d="M4.9,269.32v55.22c0,18.97,15.38,34.34,34.34,34.34h119.16c7.73,0,13.99,6.26,13.99,13.99v82.88 c0,18.97,15.38,34.34,34.34,34.34h77.62c5.51,0,9.97-4.46,9.97-9.97v0c0-5.51-4.46-9.97-9.97-9.97h-78.04 c-7.73,0-13.99-6.26-13.99-13.99v-84c0-18.35-14.87-33.22-33.22-33.22H38.83c-7.73,0-13.99-6.26-13.99-13.99v-56.05 c0-7.73,6.26-13.99,13.99-13.99h417.34c7.73,0,13.99,6.26,13.99,13.99v56.05c0,7.73-6.26,13.99-13.99,13.99H335.89 c-18.35,0-33.22,14.87-33.22,33.22v80.61c0,5.51,4.46,9.97,9.97,9.97h0c5.51,0,9.97-4.46,9.97-9.97v-79.9 c0-7.73,6.26-13.99,13.99-13.99h119.16c18.97,0,34.34-15.38,34.34-34.34v-55.22c0-18.97-15.38-34.34-34.34-34.34H39.25 C20.28,234.98,4.9,250.36,4.9,269.32z"
        />
      </g>
    </svg>
  );
}
