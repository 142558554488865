import React from "react";
import { animated, SpringValue } from "react-spring";

interface InputFieldProps {
  id: string;
  type: string;
  placeholder: string;
  value: SpringValue<string> | string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  iconSrc: string;
  iconAlt: string;
  isTextarea?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  id,
  type,
  placeholder,
  value,
  onChange,
  iconSrc,
  iconAlt,
  isTextarea = false,
}) => (
  <li
    className={`MenuOption MenuOptions-field ${
      isTextarea ? "MenuOptions-field_text" : ""
    }`}
  >
    <label htmlFor={id} className="MenuOption-label_icon">
      <img className="MenuOption-icon" src={iconSrc} alt={iconAlt} />
    </label>
    {isTextarea ? (
      <animated.textarea
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="MenuOption-input MenuOption-input_textarea"
      />
    ) : (
      <animated.input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="MenuOption-input"
      />
    )}
  </li>
);

export default InputField;
