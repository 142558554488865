import brandedDark from "./branded.dark.json";
import brandedLight from "./branded.light.json";
import classicDark from "./classic.dark.json";
import classicLight from "./classic.light.json";

export type ymapCustomizationLabels =
  | "BrandedDark"
  | "BrandedLight"
  | "ClassicDark"
  | "ClassicLight";

export const getCustomizationJSON = (label: ymapCustomizationLabels): any => {
  switch (label) {
    case "BrandedDark":
      return brandedDark;
    case "BrandedLight":
      return brandedLight;
    case "ClassicDark":
      return classicDark;
    case "ClassicLight":
      return classicLight;
  }
};
